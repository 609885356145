body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
}

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
}

h1 {
  font-size: 48px;
  margin: 0;
}

p {
  font-size: 24px;
  margin-top: 20px;
  color: #666
}